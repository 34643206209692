import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

import { useAppContext } from '../state';
import { QueryFragments } from '../GraphQl/queryFragments'; // eslint-disable-line

// Components
import { PageMeta } from '../components/pageMeta';
import BlockZone from '~blockZone';

const PageTemplate = ({ data: { page } }) => {
  const { themeDark, reverseHeader, solidHeader, meta, pageBlocks, hideFooter } = page || {};

  const { setReverseNav, setHideFooter, setSolidHeader } = useAppContext();

  useEffect(() => {
    document.documentElement.classList.remove('post');
    if (themeDark) {
      document.documentElement.classList.add('themeDark');
      setReverseNav(true);
    } else {
      document.documentElement.classList.remove('themeDark');
      setReverseNav(reverseHeader);
    }
    setHideFooter(hideFooter);
    setSolidHeader(solidHeader);
  }, [reverseHeader, setReverseNav, themeDark, hideFooter, setHideFooter, solidHeader]);

  return (
    <>
      <PageMeta {...meta} />
      {pageBlocks && <BlockZone {...pageBlocks} />}
    </>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query standardPageQuery($slug: String!) {
    page: sanityPage(slug: { current: { eq: $slug } }) {
      reverseHeader
      solidHeader
      themeDark
      hideFooter
      meta {
        ...MetaCardFields
      }
      pageBlocks {
        ...BlockZone
      }
    }
  }
`;
